import "../styles/globals.css";
import { AppWrapper } from "../contexts/StoreDataContext";
import useLocalStorage from "./../hooks/useLocalStorage";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "react-query";

function MyApp({ Component, pageProps }) {
  const [cart, setCart] = useLocalStorage("store", []);
  const router = useRouter();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },
  });

  const GTM_ID = "GTM-NSVMFWJ9";

  useEffect(() => {
    if (cart && cart.facebookPixel) {
      import("react-facebook-pixel")
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(cart.facebookPixel); // facebookPixelId
          ReactPixel.pageView();
          router.events.on("routeChangeComplete", () => {
            ReactPixel.pageView();
          });
        });
    }
  }, [router.events]);
  return (
    <AppWrapper sharedState={pageProps?.storeData}>
      <Head>
        <script
          async
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){ c[a]=c[a]
              ||function(){(c[a].q=c[a].q||[]).push(arguments)};
               t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i
               ; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })
               (window, document, "clarity", "script", "mz4kcjogks");`,
          }}
        />
        <script id="gtm" strategy="afterInteractive">
          {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `}
        </script>
      </Head>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
    </AppWrapper>
  );
}

export default MyApp;
